<template>
  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

    <b-card>

        <!-- <b-row>
          <b-col md=12>

            <div class="demo-inline-spacing float-right ">
              
                <b-button
                  variant="danger"
                  class="mt-0 mb-1"
                  :to="{ name: 'hand-sanitizer-buildings'}"
                >
                <feather-icon
                    icon="TargetIcon"
                    class="mr-25"
                  />
                  <span>Settings</span>
                </b-badge>
              </b-button>
            </div>
          </b-col>
        </b-row> -->

        <b-alert
          v-model="showDismissibleAlert"
          variant="danger"
          dismissible
          class="mb-1"
        >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        <b-row>

          <b-col md="12">
              
              <h4 class="card-title float-left"> Lactation Checklist</h4>
              
          </b-col>
          
        </b-row>

        <b-row>

          <b-col md="4">
              
              <b-form-group
                  label="Project Site"
                  class="required"

                >
                  <b-form-select v-model="site" @change="siteChange()">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    
                    <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                    
                    
                  </b-form-select>
                    
                </b-form-group>
              
          </b-col>

          <b-col md="4" >
            <b-form-group
              label="Building"
              class="required"

            >
              <v-select
                  v-model="building"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="buildings"
                  placeholder="Select"
                  @input="dataList();getLevels()"
                  :clearable="false"
              >
                <template #selected-option="{ name }">
                  <span v-if="name.length < 23">{{name}}</span>
                  <span v-if="name.length >= 23">{{name.substring(0, 23) + '...'}}</span>
                </template>
              </v-select>
                
            </b-form-group>
          </b-col>

          <b-col md="4" >
            <b-form-group
              label="Level"
              class="required"

            >
              <v-select
              v-model="level"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="levels"
              placeholder="Select"
              multiple
              :close-on-select=false
                            :clear-on-select=false
              >
              </v-select>
                
            </b-form-group>
          </b-col>

        </b-row>

        <b-row>

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2">
            
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="months"              
              class="w-100"
              placeholder="Month"
              @input="dataList()"
              v-model="month"
              :clearable="false"
            />
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2">
            
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="yearData"
              class="w-100"
              placeholder="Year"
              @input="dataList()"
              v-model="year"
              :clearable="false"
            />
          </b-col>
          
          
        </b-row> 
    
    </b-card>
    
    <b-form @submit="formSubmit">
    <b-card no-body class="mb-0">
        
        <b-table sticky-header
              responsive
              show-empty
              ref = "refUserListTable"
              class = "position-relative largeTableFH"
              primary-key = "id"
              empty-text = "No records found"
              :fields = "tableColumns"
              :items = "checklistData"
          >

          <template #cell(date)="items">
           
            <!-- <b-form-datepicker v-model="items.item.date" :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }" locale="en"/> -->

            <flat-pickr
              v-model="items.item.date"
              class="form-control"
              placeholder="Select Date"
              :config="{ altInput: true,altFormat: 'd M, Y', dateFormat: 'Y-m-d',}"
            />
        
          
         
          </template>

          <template #cell(time)="items">
            
            <flat-pickr
                v-model="items.item.time"
                class="form-control"
                :config="{ enableTime: true,minuteIncrement:1, noCalendar: true, dateFormat: 'H:i'}"
            />
          </template>
          

          <template #cell(hand_tissue_available)="items">
           
            <b-form-checkbox
              v-model="items.item.hand_tissue_available"
              value="yes"
              unchecked-value="no"
              class="custom-control-success"
            />
          </template>

          <template #cell(fridge_cleanliness)="items">
           
            <b-form-checkbox
              v-model="items.item.fridge_cleanliness"
              value="yes"
              unchecked-value="no"
              class="custom-control-success"
            />
          </template>
          <template #cell(hand_soap)="items">
           
            <b-form-checkbox
              v-model="items.item.hand_soap"
              value="yes"
              unchecked-value="no"
              class="custom-control-success"
            />
         
          </template>
          <template #cell(basin_and_table)="items">
           
            <b-form-checkbox
              v-model="items.item.basin_and_table"
              value="yes"
              unchecked-value="no"
              class="custom-control-success"
            />
         
          </template>
          <template #cell(floor)="items">
           
            <b-form-checkbox
              v-model="items.item.floor"
              value="yes"
              unchecked-value="no"
              class="custom-control-success"
            />
         
          </template>
          <template #cell(remove_trash)="items">
           
            <b-form-checkbox
              v-model="items.item.remove_trash"
              value="yes"
              unchecked-value="no"
              class="custom-control-success"
            />
         
          </template>
          <template #cell(compartment_tray)="items">
           
            <b-form-checkbox
              v-model="items.item.compartment_tray"
              value="yes"
              unchecked-value="no"
              class="custom-control-success"
            />
         
          </template>
          <template #cell(air_vent)="items">
           
            <b-form-checkbox
              v-model="items.item.air_vent"
              value="yes"
              unchecked-value="no"
              class="custom-control-success"
            />
         
          </template>
          <template #cell(sofa)="items">
           
            <b-form-checkbox
              v-model="items.item.sofa"
              value="yes"
              unchecked-value="no"
              class="custom-control-success"
            />
         
          </template>
          <template #cell(cabinet)="items">
           
            <b-form-checkbox
              v-model="items.item.cabinet"
              value="yes"
              unchecked-value="no"
              class="custom-control-success"
            />
         
          </template>

          <template #cell(glass_table)="items">
           
            <b-form-checkbox
              v-model="items.item.glass_table"
              value="yes"
              unchecked-value="no"
              class="custom-control-success"
            />
         
          </template>

          <template #cell(checked_by)="items">
            <b-form-input
              autocomplete="off"
              placeholder="3 to 50 characters"
              v-model="items.item.checked_by"
            />
          </template>


          <template #cell(remarks)="items">
            <b-form-input
              autocomplete="off"
              placeholder="500 characters"
              v-model="items.item.remarks"
            />
          </template>

          <template #cell(actions)="items">
            <b-button
              size="sm"
              variant="danger"
              class="mr-1"
              @click="removeData(items.index)"
            >
             X
            </b-button>
          </template>

          

        </b-table>
    </b-card>

    <b-card class="mt-1">
      <b-row>
        <b-col>

          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            class = "mr-1"
            variant="outline-secondary"
            @click="$router.go(-1)"
          >
            Cancel
          </b-button>

          

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mr-1"
            type="submit"
          >
            Submit
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1 float-right"
            @click="addMore"
            v-if="site != ''"
          >
            Add More
          </b-button>

        </b-col>
      </b-row>

    </b-card>

    </b-form>


      

    

  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BFormCheckbox,BFormDatepicker,BBreadcrumb
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import 'viewerjs/dist/viewer.css'
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
var moment = require('moment-timezone');
import SupervisorSignature from './SupervisorSignature.vue';
import Bus from "../../../event-bus";

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,Datepicker,BFormCheckbox,flatPickr,vSelect,BFormDatepicker,SupervisorSignature,BBreadcrumb
  },
  
  data() {
    return {
      //locations:[],
      
      date:moment().tz('Asia/Singapore').format('YYYY-MM-DD'),
      
      site:'' /*this.$route.params.site_id*/,
      buildings:[],
      building:null,
      level:[],
      levels:[],
      sites:[],
      id:null,

      showDismissibleAlert:false,
      error_message:'',
      doc_icon: require('@/assets/images/doc.png'),
      /*checklistData:[{
        date:null,
        time:'',
        hand_tissue_available:null,
        fridge_cleanliness:null,
        hand_soap:null,
        basin_and_table:null,
        floor:null,
        remove_trash:null,
        compartment_tray:null,
        air_vent:null,
        sofa:null,
        cabinet:null,
        glass_table:null,
        checked_by:'',
        remarks:'',
      }],*/
      checklistData:[],
      
      openedSignModel:null,

      tableColumns:[
        { key: 'date', label: 'Date', sortable: false , thStyle:  {width: '7%'}},
        { key: 'time', label: 'Time', sortable: false , thStyle:  {width: '5%'}},
        { key: 'hand_tissue_available', label: 'Hand Tissue Available & Dispensers Working', sortable: false , thStyle:  {width: '9%'}},
        { key: 'fridge_cleanliness', label: 'Fridge Cleanliness & Defect', sortable: false , thStyle:  {width: '7%'}},
        { key: 'hand_soap', label: 'Hand-Soap Available & Top Up', sortable: false , thStyle:  {width: '7%'}},

        { key: 'basin_and_table', label: 'Basin & Table Top Clean and Dry ', sortable: false , thStyle:  {width: '7%'}},
        { key: 'floor', label: 'Floor (Clean & Dry)', sortable: false , thStyle:  {width: '5%'}},
        { key: 'remove_trash', label: 'Remove Trash From Trash Bin', sortable: false , thStyle:  {width: '6%'}},
        { key: 'compartment_tray', label: 'Compartment Tray (4 tier)', sortable: false , thStyle:  {width: '6%'}},
        
        { key: 'air_vent', label: 'Air Vent', sortable: false , thStyle:  {width: '5%'}},
        { key: 'sofa', label: 'Sofa', sortable: false , thStyle:  {width: '5%'}},
        
        { key: 'cabinet', label: 'Cabinet', sortable: false , thStyle:  {width: '5%'}},
        { key: 'glass_table', label: 'Glass Table', sortable: false , thStyle:  {width: '6%'}},
        { key: 'checked_by', label: 'Checked by', sortable: false , thStyle:  {width: '7%'}},
        { key: 'remarks', label: 'Remarks', sortable: false , thStyle:  {width: '7%'}},
        
        { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '5%'}},

      ],
      months:[
         'January', 
         'February', 
         'March', 
         'April', 
         'May', 
         'June', 
         'July ', 
         'August', 
         'September', 
         'October', 
         'November', 
         'December'
      ],
      month:moment().format('MMMM'),
      year:parseInt(moment().format('YYYY')),
      yearData:[],
      
    }

  },
  directives: {
    Ripple,
  },
  methods: {
    showAlert() {
            
        Swal.fire({
          position: 'center',
          icon: 'error',
          text: this.error_message,
          showConfirmButton: false,
          timer: 3000
        })
    },

    
    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
             role:this.$store.getters.currentUser.role,
             //om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;

                if (this.sites.length == 1) {
                            
                    this.site = this.sites[0]._id;
                    this.siteChange();
                }

                return this.sites;
            }
        });
    },
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
        data:{
            id: this.id,
            site  :this.site,
            level : this.level,
            building : this.building,
            month : this.month ? this.month.toLowerCase() : null,
            year  : this.year,
            checklistData : this.checklistData,
        },
        api : '/api/submit-lactation-checklist'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message        = this.$store.getters.getErrors;
              this.showDismissibleAlert = true;

              window.scrollTo(0,0);
          } else {
              this.showDismissibleAlert = false;

              var data = this.$store.getters.getResults.data;

              this.successAlert().then((result) => {

                this.site = ''/*this.$route.params.site_id*/;
                /*this.checklistData = [];
                this.building = null;
                this.levels = [];
                this.level = [];
                

                this.addMore();*/

                if (this.sites.length == 1) {        
                  this.site = this.sites[0]._id;
                }else{
                  this.site = '';
                }

                this.siteChange();

                if (data != null) {
                    window.open(data, '_blank');
                }

                this.$router.go(-1);
                  
              });
              
          }
      });
    },
    calYear(){
      var year = parseInt(moment().format('YYYY'));
      var yearData = [];
      var start = parseInt(year) - 2;
      var end =  parseInt(year) + 5;

      for (var i = start ; i <= end; i++) {
        yearData.push(i);
      }

      this.yearData = yearData;
    },
    addMore(){

      var obj = {
        date:null,
        time:'',
        hand_tissue_available:'no',
        fridge_cleanliness:'no',
        hand_soap:'no',
        basin_and_table:'no',
        floor:'no',
        remove_trash:'no',
        compartment_tray:'no',
        air_vent:'no',
        sofa:'no',
        cabinet:'no',
        glass_table:'no',
        checked_by:'',
        remarks:'',
      };

      this.checklistData.push(obj);
    },
    removeData(index){
      this.checklistData.splice(index,1);
      if (this.checklistData.length == 0) {
        this.addMore();
      }
    },
    filterTable(){
        this.$refs.refUserListTable.refresh();
    },
    dataList(){
        return this.$store.dispatch(POST_API, {
            data:{
                site : this.site,
                month:this.month ? this.month.toLowerCase() : null,
                year:this.year,
                building : this.building ? this.building._id : null,
            },
            api: '/api/lactation-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                var data  = this.$store.getters.getResults.data;
                
                if (data) {
                  this.id = data._id;
                  this.checklistData = data.checkListData;
                  this.level = data.level;

                }else{
                  this.id = null;
                  this.checklistData = [];
                  this.level = []
                  this.addMore();
                }
               
                return this.checklistData;
            }
        });
    },
    getBuilding(){
      
      this.levels = [];
      this.level = [];

      return this.$store.dispatch(POST_API, {
          data:{
              site:this.site
          },
          api: '/api/all-buildings'
      })
      .then(() => {
          if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
          } else {
              this.buildings    = this.$store.getters.getResults.data;
              return this.buildings;
          }
      }); 
    },
    getLevels(){

      this.levels = [];
      this.level = [];

      if (this.building.basement == 'yes') {

          for (var i = 1; i <= this.building.no_of_basements; i++) {
              this.levels.push('Basement '+i);
          }

      }

      for (var j = 1; j <= this.building.no_of_levels; j++) {
          this.levels.push('Level '+j);
      }

      return this.levels;
    },
    siteChange(){

      this.checklistData = [];
      this.building = null;
      this.levels = [];
      this.level = [];
      this.buildings = [];

      if (this.site != '') {
        
        this.getBuilding();
        this.dataList();
      }
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:{name:'custom-forms'},
        text: 'Custom Forms',
      },{
        to:null,
        text:'Lactation Checklist',   
        active:true             
      }];
      return item;
    }
  },
  mounted(){
    this.allSites();
    this.calYear();
    
  }
  
  
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
